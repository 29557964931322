<template>
    <div class="history_box">
        <div class="sub_title flex_box">
            <img src="../../assets/img/detail/title_ic16.png" />
            Add Credit
        </div>
        <div class="computer_right_cont_box">
            <div class="companyservices">Company Search Platform Services</div>
            <div class="price_box flex_center_between_box">
                <div class="flex_center_between_box phone_flex_box" style="width: 100%">
                    <div class="card_img card_img1" @click="show(0)">
                        <span class="font-title">
                            <div class="com">Company</div>
                            <div class="sec">Search</div>
                        </span>
                        <div v-if="zhanshi == 0" class="card_img1_sanjiao"></div>
                    </div>
                    <div class="card_img card_img2" @click="show(1)">
                        <span class="font-title">
                            <div class="com">Product</div>
                            <div class="sec">Search</div>
                        </span>
                        <div v-if="zhanshi == 1" class="card_img1_sanjiao card_img1_sanjiao1"></div>
                    </div>
                    <div class="card_img card_img3" @click="show(2)">
                        <span class="font-title">
                            <div class="com">Trade</div>
                            <div class="sec">Search</div>
                        </span>
                        <div v-if="zhanshi == 2" class="card_img1_sanjiao card_img1_sanjiao2"></div>
                    </div>
                    <div class="card_img card_img4" @click="show(3)">
                        <span class="font-title">
                            <div class="com">Sanctions</div>
                            <div class="sec">Search</div>
                        </span>
                        <div v-if="zhanshi == 3" class="card_img1_sanjiao card_img1_sanjiao3"></div>
                    </div>
                    <div class="card_img card_img5" @click="show(4)">
                        <span class="font-title">
                            <div class="com">Country</div>
                            <div class="sec">Search</div>
                        </span>
                        <div v-if="zhanshi == 4" class="card_img1_sanjiao card_img1_sanjiao4"></div>
                    </div>
                    <div class="card_img card_img6" @click="show(5)">
                        <span class="font-title">
                            <div class="com">News</div>
                            <div class="sec">Search</div>
                        </span>
                        <div v-if="zhanshi == 5" class="card_img1_sanjiao card_img1_sanjiao5"></div>
                    </div>
                </div>
            </div>

            <div v-if="zhanshi == 0" class="">
                <div class="icon-text">
                    <div class="icon-text" style="width: 180px; justify-content: center" v-for="(item, index) in iconlist" :key="index">
                        <div class="icone flex_center" style="background-color: #55b419">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="icon-text-box1">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="zhanshi == 1" class="">
                <div class="icon-text">
                    <div class="icon-text" style="width: 180px; justify-content: center" v-for="(item, index) in iconlist1" :key="index">
                        <div class="icone flex_center" style="background: rgba(255, 192, 0)">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="icon-text-box1">{{ item.title }}</div>
                    </div>
                </div>
            </div>

            <div v-if="zhanshi == 2" class="">
                <div class="icon-text">
                    <div class="icon-text" style="width: 180px; justify-content: center" v-for="(item, index) in iconlist2" :key="index">
                        <div class="icone flex_center" style="background-color: rgba(237, 125, 49)">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="icon-text-box1">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="zhanshi == 3" class="">
                <div class="icon-text">
                    <div class="icon-text" style="width: 180px; justify-content: center" v-for="(item, index) in iconlist3" :key="index">
                        <div class="icone flex_center" style="background-color: #1290c9">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="icon-text-box1">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="zhanshi == 4" class="">
                <div class="icon-text">
                    <div class="icon-text" style="width: 180px; justify-content: center" v-for="(item, index) in iconlist4" :key="index">
                        <div class="icone flex_center" style="background: cyan">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="icon-text-box1">{{ item.title }}</div>
                    </div>
                </div>
            </div>
            <div v-if="zhanshi == 5" class="">
                <div class="icon-text">
                    <div class="icon-text" style="width: 180px; justify-content: center" v-for="(item, index) in iconlist5" :key="index">
                        <div class="icone flex_center" style="background: cornflowerblue">
                            <img :src="item.icon" alt="" />
                        </div>
                        <div class="icon-text-box1">{{ item.title }}</div>
                    </div>
                </div>
                <!-- <div class="icon-text">
                    <div class="icon-text-box" v-for="(item,index) in iconlist5" :key="index">{{ item.title }}</div>
                 
            
               
                 </div> -->
            </div>
        </div>
        <!-- 手机端 -->
        <div class="das_top_cont phone_page_bg">
            <ul class="das_top_list flex_center_between_box phone_flex_box" style="margin-bottom: 30px">
                <li class="das_top_item">
                    <div class="item_img color1">
                        <img src="../../assets/img/addcreate/iconsousuo.png" />
                    </div>

                    <div class="item_group">
                        <div class="item_txt">Seach Companies</div>
                        <label class="item_lab">unlimited</label>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color2">
                        <img src="../../assets/img/addcreate/gongyingshang.png" />
                    </div>

                    <div class="item_group">
                        <div class="item_txt">Find Partners</div>
                        <label class="item_lab">4 units/co</label>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color3">
                        <img src="../../assets/img/server-update.png" />
                    </div>
                    <div class="item_group">
                        <div class="item_txt">View Company Details</div>
                        <label class="item_lab">1 units/co</label>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color4">
                        <img src="../../assets/img/addcreate/baogaoxiazai.png" />
                    </div>
                    <div class="item_group">
                        <div class="item_txt">Order Report</div>
                        <label class="item_lab">10 units/co</label>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color5">
                        <img src="../../assets/img/addcreate/tandem.png" />
                    </div>
                    <div class="item_group">
                        <div class="item_txt">Shareholder Penetration</div>
                        <label class="item_lab">2 units/co</label>
                    </div>
                </li>
                <li class="das_top_item">
                    <div class="item_img color6">
                        <img src="../../assets/img/addcreate/shujudaochu.png" />
                    </div>
                    <div class="item_group">
                        <div class="item_txt">Import & Export Data</div>
                        <label class="item_lab">2 units/co</label>
                    </div>
                </li>
            </ul>
        </div>
        <div class="cre_title">Customize your plan however you need</div>
        <!-- pc端充值 -->
        <div class="computer_right_cont_box">
            <div class="price_box flex_center_between_box phone_flex_box">
                <div v-for="(item, index) in productList.slice(0, -1)" :key="index" v-show="index != (isNew == true ? 0 : 1)" :class="{ price_item_box: index == 0, price_item_box1: index == 1, price_item_box2: index == 2 }">
                    <div class="price_item_titel" :class="{ yinying: isNew && index == 0, name_0: index == 0, name_1: index == 1, name_2: index == 2 }">
                        <img v-show="false" class="price_item_ic" src="../../assets/img/detail/creadit_ic1.png" />
                        <div class="price_name">{{ item.name }}</div>
                    </div>
                    <div class="price_des" :class="{ yinying: isNew && index == 0, des_0: index == 0, des_1: index == 1, des_2: index == 2 }">
                        <el-checkbox-group v-if="index == 0" v-model="companySizeCheckList">
                            <el-checkbox v-for="(child, i) in item.productDetailList" :key="i" label-position="left" @change="menucheck()" :class="{ 'hide-checkbox': child.code != '1' }" :label="child.code" :disabled="true">
                                <div v-if="child.code == '1'" class="line_1">
                                    <span style="font-size: 15px">{{ child.name }}</span>
                                    <li class="line_1 coll_child_name" v-for="(sonchild, soni) in child.subProductDetailList?.slice(0, 1)" :key="soni">
                                        {{ sonchild.name }}
                                        <div class="check-box" style="position: absolute; left: 314px; line-height: 22px; top: 22px"><div class="check-son"></div></div>
                                    </li>
                                </div>
                                <div v-else class="line_1">
                                    <span style="font-size: 15px">{{ child.name }}</span>
                                    <span style="position: absolute; left: 305px">
                                        {{ child?.price }}
                                        <span>{{ child?.currency }}</span>
                                    </span>
                                    <li class="line_1 coll_child_name" v-for="(sonchild, soni) in child.subProductDetailList" :key="soni">
                                        {{ sonchild.name }}
                                        <div class="check-box" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                    </li>
                                </div>
                            </el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="index == 1" v-model="companySizeCheckList1">
                            <el-checkbox v-for="(child, i) in item.productDetailList" :key="i" v-show="child.type == 1" label-position="left" :class="{ 'hide-checkbox': child.code != '1' && child.code != '10014' && child.code != '10013' }" @change="menucheck()" :label="child.code" :disabled="true">
                                <!-- <div class="line_1">{{  child.name }}
                                <li class="line_1 coll_child_name coll_child_name1" v-for="(sonchild,soni) in child.subProductDetailList" :key="soni">
                             {{ sonchild.name }}
                            </li>
                            </div> -->
                                <div v-if="child.code == '1'" class="line_1">
                                    <div class="line_1" style="font-size: 15px; width: 200px; display: inline-block">{{ child.name }}</div>
                                    <li class="line_1 coll_child_name coll_child_name1" v-for="(sonchild, soni) in child.subProductDetailList?.slice(0, 1)" :key="soni">
                                        {{ sonchild.name }}
                                        <div class="check-box check-box1" style="position: absolute; left: 314px; line-height: 22px; top: 22px"><div class="check-son"></div></div>
                                    </li>
                                </div>
                                <div v-else-if="child.name == 'Unit price discount for recharge account:'" class="line_1" style="white-space: break-spaces">
                                    <div class="line_1" style="font-size: 15px; width: 195px; display: inline-block">{{ child.name }}</div>
                                    <span style="position: absolute; left: 275px">
                                        {{ child.discount * 100 }}
                                        <span>%</span>
                                    </span>
                                </div>
                                <div v-else class="line_1">
                                    <div class="line_1" style="font-size: 15px; width: 300px; display: inline-block">{{ child.name }}</div>
                                    <span style="position: absolute; left: 300px">
                                        {{ child?.price }}
                                        <span>{{ child?.currency }}</span>
                                    </span>
                                    <li class="line_1 coll_child_name coll_child_name1" v-for="(sonchild, soni) in child.subProductDetailList" :key="soni">
                                        {{ sonchild.name }}
                                        <div class="check-box check-box1" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                    </li>
                                </div>
                            </el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group v-if="index == 2" v-model="companySizeCheckList2">
                            <el-checkbox v-for="(child, i) in item.productDetailList" :key="i" label-position="left" :class="{ 'hide-checkbox': child.code == '10016' }" :label="child.code" @change="chckenum(child)" :disabled="0 < i && i <= 6 ? (item.productDetailcods.includes(child.code) ? true : false) : true">
                                <!-- <li class="line_1 coll_child_name coll_child_name2">{{ child.name }}</li> -->
                                <div v-if="child.code == '1'" class="line_1">
                                    <span style="font-size: 15px">{{ child.name }}</span>
                                    <li class="line_1 coll_child_name coll_child_name2" v-for="(sonchild, soni) in child.subProductDetailList.slice(1, 2)" :key="soni">
                                        {{ sonchild.name }}
                                        <div class="check-box check-box2" style="position: absolute; left: 314px; line-height: 22px; top: 22px"><div class="check-son"></div></div>
                                    </li>
                                </div>
                                <div v-else-if="child.name == 'Unit price discount for recharge account:'" class="line_1" style="white-space: break-spaces">
                                    <div class="line_1" style="font-size: 15px; width: 195px; display: inline-block">{{ child.name }}</div>
                                    <span style="position: absolute; left: 275px">
                                        {{ child.discount * 100 }}
                                        <span>%</span>
                                    </span>
                                </div>

                                <div v-else class="line_1">
                                    <div class="line_1" style="font-size: 15px; width: 300px; display: inline-block; color: #022955">{{ child.name }}</div>
                                    <span v-if="child?.currency == 'unit'" style="position: absolute; left: 305px">
                                        {{ child?.price }}
                                        <span>{{ child?.currency == 'usd' ? '/yr' : child?.currency }}</span>
                                    </span>
                                    <span v-else style="position: absolute; left: 250px; color: #022955">
                                        <i style="font-style: inherit" v-if="child.code != '7'">$</i>
                                        {{ child?.price }}
                                        <span>{{ child?.currency == 'usd' ? '/yr' : child?.currency }}</span>
                                    </span>
                                    <li class="line_1 coll_child_name coll_child_name2" v-for="(sonchild, soni) in child.subProductDetailList" :key="soni">
                                        {{ sonchild.name }}
                                        <!-- <div v-if="sonchild.name!='Sanction detail info'" class="check-box check-box2" style="position: absolute;left: 314px;line-height: 22px;"><div class="check-son"></div></div> -->
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '2'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('2') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '3'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('3') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '9'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('9') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '10'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('10') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <span v-if="sonchild.name == 'Sanction detail info'" style="position: absolute; left: 305px">
                                            {{ sonchild?.price }}
                                            <span>{{ sonchild?.currency }}</span>
                                        </span>
                                    </li>
                                </div>
                                <!-- <span class="coll_child_num line_1" v-if="0 < i && i < 6">${{ child.price | FilterNum }}</span> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="price_item" :class="{ yinying: isNew && index == 0 }">
                        <div class="price_item_bottom">
                            <div class="inputtitle" v-if="index < 2">
                                <!-- oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" -->
                                <el-input v-model="input" type="number" class="inputttext" max="4080" @input="inputnum()" v-if="index == 1" placeholder=""></el-input>
                                <span v-else class="inputttext2">{{ item.num }}</span>
                                <span style="margin-left: 8px">units</span>
                            </div>
                            <div class="inputtitle inputprive" v-show="index == 2">
                                <div v-if="checkshow">
                                    <span class="inputttext2" style="margin-right: 8px">$ {{ item.actualPrice | FilterNum }}</span>
                                    <span>/year</span>
                                </div>
                            </div>
                            <!-- <div class="price" :class="index == 2 ? 'price2' : ''"> -->
                            <div class="price">
                                <span v-if="index != 2">$ {{ item.actualPrice | FilterNum }}</span>
                                <!-- <span style="color: #8497ab; font-size: 14px" v-if="index == 2">/year</span> -->
                                <!-- <div v-if="index == 2" style="font-size: 14px;color: #8497ab;line-height: 16px;" >or <span  style="font-size: 30px;">$ {{ NumFormat(item.actualPrice/10) }}</span>/mounth</div> -->
                                <div v-if="index < 2" style="font-size: 14px; color: #8497ab; line-height: 16px">
                                    /original $
                                    <span class="actual_price">{{ NumFormat(item?.price) }}</span>
                                </div>
                            </div>

                            <div class="limit_txt line_2">{{ item?.description }}</div>
                        </div>
                        <div class="button_box">
                            <button v-if="index != 2" :disabled="isNew && index == 0" class="btn_buy flex_center" :class="{ yinying: isNew && index == 0, buy_0: index == 0, buy_1: index == 1, buy_2: index == 2 }" @click="payNow(item, index)">
                                <img v-if="!isNew && index == 0" src="../../assets/img/detail/btn_ic6.png" />
                                <span v-if="!isNew && index == 0">Buy Now</span>
                                <img v-if="index == 1" src="../../assets/img/detail/btn_ic6.png" />
                                <span v-if="index == 1">Buy Now</span>
                            </button>
                            <button v-else :disabled="checkisabled" class="btn_buy flex_center" :class="{ yinying: checkisabled && index == 2, buy_0: index == 0, buy_1: index == 1, buy_2: index == 2 }" @click="payNow(item, index)">
                                <img src="../../assets/img/detail/btn_ic6.png" />
                                You are Subscriber
                            </button>
                        </div>

                        <!-- <div class="price_des" :class="'des_' + index" v-html="item.description"></div> -->
                    </div>
                </div>

                <!-- 自定义价格 -->
                <div class="price_item_box3">
                    <div class="price_item_titel name_3">
                        <img v-show="false" class="price_item_ic" src="../../assets/img/detail/creadit_ic1.png" />
                        <div class="price_name">Customized</div>
                    </div>

                    <div class="price_des des_3">
                        <el-checkbox-group v-model="companySizeCheckList3">
                            <el-checkbox v-for="(child, i) in companySizeCheckList4" :key="i" label-position="left" :label="child.code" :disabled="true">
                                <li class="line_1 coll_child_name coll_child_name3">{{ child.name }}</li>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>

                    <div class="price_item">
                        <div class="price_item_bottom">
                            <div class="price price2">
                                <a href="mailto:request@csibizinfo.com" style="color: #022955">Contact Us</a>

                                <!-- {{ defProduct.actualPrice }} -->
                            </div>
                            <!-- <div class="limit_txt line_2">{{ defProduct.preferentialText }}</div> -->
                            <div class="limit_txt line_2">{{ productList[3]?.description }}</div>
                        </div>
                        <div class="button_box">
                            <div class="btn_buy flex_center buy_us">
                                <img src="../../assets/img/detail/btn_ic7.png" />
                                <a href="mailto:request@csibizinfo.com" style="color: #fff">Contact Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 手机端充值 -->
        <div class="phone_page_bg phone_addcreade">
            <div class="price_box flex_center_between_box phone_flex_box">
                <div v-for="(item, index) in productList.slice(0, -1)" :key="index" v-show="index != (isNew == true ? 0 : 1)" :class="{ price_item_box: index == 0, price_item_box1: index == 1, price_item_box2: index == 2 }">
                    <div class="price_item_titel" :class="{ yinying: isNew && index == 0, name_0: index == 0, name_1: index == 1, name_2: index == 2 }">
                        <img v-show="false" class="price_item_ic" src="../../assets/img/detail/creadit_ic1.png" />
                        <div class="price_name">{{ item.name }}</div>
                    </div>
                    <div class="price_des" :class="{ yinying: isNew && index == 0, des_0: index == 0, des_1: index == 1, des_2: index == 2 }">
                        <div v-if="index == 0">
                            <ul class="add_price_des">
                                <li>You can view 30 companies ($6/online report) , or.</li>
                                <li>Order 3 company credit report (S60/FiES in investigation report), or.</li>
                                <li>Check 10 company shareholder structure and penetration, or.</li>
                                <li>Let "Find Partners" recommend 5 potential customers or vendors for you.</li>
                                <li>Shareholder penetration 2 unit.</li>
                                <li>Import & export info 2 unit.</li>
                                <li>For new user only.</li>
                            </ul>
                        </div>
                       <div v-if="index == 1">
                            <ul class="add_price_des">
                                <li>Use credits to search companies all over the world.</li>
                                <li>You can access global database instantly.</li>
                                <li>You can order credit reports on any global comoanies.</li>
                                <li>You can find your potential business partners by smart search.</li>
                                <li>You can search for trade information.</li>
                                <li>Unit price discount for recharge account:20%.</li>
                            </ul>
                        </div>
                        <el-checkbox-group v-if="index == 2" v-model="companySizeCheckList2">
                            <el-checkbox v-for="(child, i) in item.productDetailList" :key="i" label-position="left" :class="{ 'hide-checkbox': child.code == '10016' }" :label="child.code" @change="chckenum(child)" :disabled="0 < i && i <= 6 ? (item.productDetailcods.includes(child.code) ? true : false) : true">
                                <!-- <li class="line_1 coll_child_name coll_child_name2">{{ child.name }}</li> -->
                                <div v-if="child.code == '1'" class="line_1">
                                    <span style="font-size: 12px">{{ child.name }}</span>
                                    <li class="line_1 coll_child_name coll_child_name2" v-for="(sonchild, soni) in child.subProductDetailList.slice(1, 2)" :key="soni">
                                        {{ sonchild.name }}
                                        <div class="check-box check-box2" style="position: absolute; left: 314px; line-height: 22px; top: 22px"><div class="check-son"></div></div>
                                    </li>
                                </div>
                                <div v-else-if="child.name == 'Unit price discount for recharge account:'" class="line_1" style="white-space: break-spaces">
                                    <div class="line_1" style="font-size: 12px; width: 195px; display: inline-block">{{ child.name }}</div>
                                    <span style="position: absolute; left: 275px">
                                        {{ child.discount * 100 }}
                                        <span>%</span>
                                    </span>
                                </div>

                                <div v-else class="line_1">
                                    <div class="line_1" style="font-size: 12px; width: 300px; display: inline-block; color: #022955">{{ child.name }}</div>
                                    <span v-if="child?.currency == 'unit'" style="position: absolute; left: 305px">
                                        {{ child?.price }}
                                        <span>{{ child?.currency == 'usd' ? '/yr' : child?.currency }}</span>
                                    </span>
                                    <span v-else style="position: absolute; left: 70%; color: #022955">
                                        <i style="font-style: inherit" v-if="child.code != '7'">$</i>
                                        {{ child?.price }}
                                        <span>{{ child?.currency == 'usd' ? '/yr' : child?.currency }}</span>
                                    </span>
                                    <li class="line_1 coll_child_name coll_child_name2" v-for="(sonchild, soni) in child.subProductDetailList" :key="soni">
                                        {{ sonchild.name }}
                                        <!-- <div v-if="sonchild.name!='Sanction detail info'" class="check-box check-box2" style="position: absolute;left: 314px;line-height: 22px;"><div class="check-son"></div></div> -->
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '2'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('2') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '3'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('3') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '9'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('9') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <div v-if="sonchild.name != 'Sanction detail info' && child.code == '10'" class="check-box check-boxwihte" :class="companySizeCheckList2.includes('10') ? 'check-box2' : 'check-boxwihte'" style="position: absolute; left: 314px; line-height: 22px"><div class="check-son"></div></div>
                                        <span v-if="sonchild.name == 'Sanction detail info'" style="position: absolute; left: 305px">
                                            {{ sonchild?.price }}
                                            <span>{{ sonchild?.currency }}</span>
                                        </span>
                                    </li>
                                </div>
                                <!-- <span class="coll_child_num line_1" v-if="0 < i && i < 6">${{ child.price | FilterNum }}</span> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="price_item" :class="{ yinying: isNew && index == 0 }">
                        <div class="price_item_bottom" :class="{flexprice:index==1}"> 
                            <div class="inputtitle" v-if="index < 2">
                                <!-- oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')" -->
                                <el-input v-model="input" type="number" class="inputttext" max="4080" @input="inputnum()" v-if="index == 1" placeholder=""></el-input>
                                <span v-else class="inputttext2">{{ item.num }}</span>
                                <span style="margin-left: 8px">units</span>
                            </div>
                            <div class="inputtitle inputprive" v-show="index == 2">
                                <div v-if="checkshow">
                                    <span class="inputttext2" style="margin-right: 8px">$ {{ item.actualPrice | FilterNum }}</span>
                                    <span>/year</span>
                                </div>
                            </div>
                            <!-- <div class="price" :class="index == 2 ? 'price2' : ''"> -->
                            <div class="price">
                                <span v-if="index != 2">$ {{ item.actualPrice | FilterNum }}</span>
                                <!-- <span style="color: #8497ab; font-size: 14px" v-if="index == 2">/year</span> -->
                                <!-- <div v-if="index == 2" style="font-size: 14px;color: #8497ab;line-height: 16px;" >or <span  style="font-size: 30px;">$ {{ NumFormat(item.actualPrice/10) }}</span>/mounth</div> -->
                                <div v-if="index < 2" style="font-size: 14px; color: #8497ab; line-height: 16px">
                                    ( /original $
                                    <span class="actual_price">{{ NumFormat(item?.price) }}</span>
                                    )
                                </div>
                            </div>

                            <div v-if="index == 0" class="inputttext2">
                                40%
                                <span>off</span>
                            </div>
                             <!-- <div v-if="index == 1" class="inputttext2">
                                20%
                                <span>off</span>
                            </div> -->
                        </div>
                        <div class="button_box">
                            <button v-if="index != 2" :disabled="isNew && index == 0" class="btn_buy flex_center" :class="{ yinying: isNew && index == 0, buy_0: index == 0, buy_1: index == 1, buy_2: index == 2 }" @click="payNow(item, index)">
                                <img v-if="!isNew && index == 0" src="../../assets/img/detail/btn_ic6.png" />
                                <span v-if="!isNew && index == 0">Buy Now</span>
                                <img v-if="index == 1" src="../../assets/img/detail/btn_ic6.png" />
                                <span v-if="index == 1">Buy Now</span>
                            </button>
                            <button v-else :disabled="checkisabled" class="btn_buy flex_center" :class="{ yinying: checkisabled && index == 2, buy_0: index == 0, buy_1: index == 1, buy_2: index == 2 }" @click="payNow(item, index)">
                                <img src="../../assets/img/detail/btn_ic6.png" />
                                You are Subscriber
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 点击 Update data 后弹出 -->
        <el-dialog :visible.sync="purchaseDialog" center :close-on-click-modal="false" custom-class="dialog_lg puy_dialog" :append-to-body="true">
            <div class="detail_cont">
                <div class="fin_title">Purchase</div>
                <el-form ref="purchaseForm" :model="purchaseForm" :rules="purchaseRules" class="form_box" label-position="left">
                    <div class="flex_box phone_flex_box">
                        <div class="purc_left">
                            <div class="pay_price m_b_20" v-if="!Subscriber">$ {{ purchaseForm?.price }}</div>
                            <div class="pay_price m_b_20" v-if="Subscriber">$ {{ purchaseForm?.price + productList[1]?.actualPrice }}</div>

                            <div class="pay_txt m_b_20">Features included in your subscription</div>
                            <div class="pay_left_sub_title">Advanced Search</div>
                            <ul class="pay_left_ul">
                                <li>
                                    <i class="el-icon-success"></i>
                                    Run unlimited searches
                                </li>
                                <li>
                                    <i class="el-icon-success"></i>
                                    Access all data types and search filters
                                </li>
                                <li>
                                    <i class="el-icon-success"></i>
                                    Identify prospects with profile comparison tools
                                </li>
                            </ul>
                            <div class="pay_left_sub_title">Tracking and Monitoring</div>
                            <ul class="pay_left_ul">
                                <li>
                                    <i class="el-icon-success"></i>
                                    Save searches and lists
                                </li>
                                <li>
                                    <i class="el-icon-success"></i>
                                    Import lists of companies and investors
                                </li>
                            </ul>
                            <div class="pay_left_sub_title">Personalization and Automation</div>
                            <ul class="pay_left_ul">
                                <li>
                                    <i class="el-icon-success"></i>
                                    Find new prospects with recommended companies
                                </li>
                                <li>
                                    <i class="el-icon-success"></i>
                                    Prioritize accounts with shared tags and notes
                                </li>
                            </ul>
                        </div>
                        <div class="purc_right">
                            <div class="flex_between_box phone_flex_box">
                                <div class="acc_right">Account Information</div>
                                <div class="acc_log">Logged in as {{ purchaseForm.loginAccount }}</div>
                            </div>
                            <div class="pay_title m_b_20">Payment Method:</div>
                            <div class="flex_box phone_flex_box">
                                <el-form-item label="Full Name :" prop="fullName" class="inpt_line">
                                    <el-input v-model="purchaseForm.fullName" maxlength="64"></el-input>
                                </el-form-item>
                                <el-form-item label="Credit Card Number :" prop="cardNumber" class="inpt_line m_l_20">
                                    <el-input v-model="purchaseForm.cardNumber" maxlength="16"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex_box phone_flex_box">
                                <el-form-item label="Exp.Month :" prop="expMonth" class="inpt_line">
                                    <el-select v-model="purchaseForm.expMonth" placeholder="Select" class="fin_top_sel">
                                        <el-option :label="item.laber" :value="item.value" v-for="(item, index) in monthOptions" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Exp.year :" prop="expYear" class="inpt_line m_l_20">
                                    <el-select v-model="purchaseForm.expYear" placeholder="Select" class="fin_top_sel">
                                        <el-option :label="item" :value="item" v-for="(item, index) in yearOptions" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="CVC Code :" prop="cardCode" class="inpt_line m_l_20">
                                    <el-input v-model="purchaseForm.cardCode" maxlength="4"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex_box phone_flex_box m_b_40">
                                <el-form-item label="Country :" prop="country" class="inpt_line">
                                    <el-select v-model="purchaseForm.country" placeholder="Select" class="fin_top_sel" @change="changeCountry">
                                        <el-option v-for="item in countryOptions" :key="item.countryCode" :label="item.nameEn" :value="item.countryCode"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Province / State :" prop="province" class="inpt_line m_l_20">
                                    <el-select v-model="purchaseForm.province" placeholder="Select" class="fin_top_sel" no-data-text="No Data" :loading="proviceArrLoading" loading-text="loading...">
                                        <el-option :label="item.name" :value="item.value" v-for="(item, index) in provinceOptions" :key="index.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Postal Code:" prop="zip" class="inpt_line m_l_20">
                                    <el-input v-model="purchaseForm.zip"></el-input>
                                </el-form-item>
                            </div>
                            <div class="total_txt m_b_20">Total Purchase</div>
                            <div class="pay_price m_b_20" v-if="!Subscriber">$ {{ purchaseForm?.price }}</div>
                            <div v-if="Subscriber" class="pay_price m_b_20">
                                $ {{ purchaseForm.price + productList[1]?.actualPrice }}
                                <span>/year</span>
                            </div>
                            <!-- <div class="inputtitle2" v-if="Subscriber">
                    <el-input v-model="input"  type="number" class="inputttext" @change="inputnum()"   placeholder=""></el-input>
           
                    <span >units</span>
                </div>
                <div class="price_eldio" v-if="Subscriber"> 
                        <span>$  {{productList[1].pice | FilterNum }}</span>
                    
                         
                    <div class="actual_price">$ {{ NumFormat(productList[1].price) }} </div>
                    </div> -->
                            <div class="tip_txt m_b_40">
                                By clicking “Submit”, you agree to the
                                <span @click="tiaokuan" style="color: #008eda">CSI Services Agreement and Privacy</span>
                                .
                            </div>
                            <div class="btn_box">
                                <el-button class="btn_export" @click="onSubmit">Submit</el-button>
                                <el-button class="btn_cancel" @click="btnCancel">Cancel</el-button>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="purchaseDialog2" center :close-on-click-modal="false" custom-class="dialog_lg puy_dialog2" :append-to-body="true">
            <div class="price_item_box2" style="margin-top: 25px; border-top: 1px solid rgba(237, 125, 49)">
                <div class="price_item price_itemtwo">
                    <div class="price_item_titel">
                        <img class="price_item_ic" src="../../assets/img/detail/creadit_ic1.png" />
                        <div class="price_name">Total</div>
                    </div>
                    <div class="price_item_bottom">
                        <!-- <div class="price_unit"  v-if="index!=2" :class="'units_bg_' + index">
                        <div class="price_unit_num">{{ item.num }}</div>
                        <div v-if="index!=2" class="price_unit_tip">units</div>
                        <div v-else class="price_unit_tip"></div>
                    </div> -->
                        <!-- index=2价格输入 -->
                        <div class="inputtitle">
                            <el-input v-model="input" type="number" class="inputttext" @input="inputnum()" placeholder=""></el-input>

                            <span style="width: 50px">units</span>
                        </div>

                        <div class="price">
                            <span>$ {{ productList[1]?.actualPrice | FilterNum }}</span>
                            <span style="color: #8497ab; font-size: 14px" v-if="index == 2">/year</span>

                            <div class="actual_price">$ {{ NumFormat(productList[1]?.price) }}</div>
                        </div>
                        <div class="pricenum">
                            <div class="pay_price">+</div>
                            <div class="pay_price m_b_20">
                                $ {{ purchaseForm?.price }}
                                <span>/year</span>
                            </div>
                        </div>
                        <div class="pricenum">
                            <div class="pay_price m_b_20" style="display: flex; justify-content: space-between">
                                <span>Toatal:</span>
                                <span>{{ purchaseForm?.price + productList[1]?.actualPrice }}</span>
                            </div>
                        </div>
                        <div class="price_desc des_2c">
                            <button class="btn_buytwo flex_center buy_2" @click="payNow(productList[2], 2, true)">
                                <img src="../../assets/img/detail/btn_ic6.png" />
                                Buy Now
                            </button>
                        </div>
                    </div>
                    <!-- <div class="price_des" :class="'des_' + index" v-html="item.description"></div> -->
                </div>
            </div>
            <!-- <div class="detail_cont">
                <div class="total_txt m_b_20">Total Purchase</div>
                            <div class="pay_price m_b_20">
                                $ {{ purchaseForm.price }}
                                <span >/year</span>
                            </div>
                            <div class="inputtitle2" >
                    <el-input v-model="input"  type="number" class="inputttext" @change="inputnum()"   placeholder=""></el-input>
           
                    <span class="untis" >units</span>
                </div>
                <div class="price_eldio" > 
                        <span>$  {{productList[1].actualPrice | FilterNum }}</span>
                    
                         
                    <div class="actual_price">$ {{ NumFormat(productList[1].price) }} </div>
                    </div>
            </div> -->
        </el-dialog>
    </div>
</template>
<script>
import { cardAuthorize, getList, getPrice, getPublicClientKey, ordersAdd, getoptions } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import { mixins1 } from '@/mixins/index';
import { createScript } from '@/utils/acceptcreate';
import bus from '@/components/bus';
// import bus from '@/components/bus'
export default {
    mixins: [mixins1],
    data() {
        return {
            index: 0,
            zhanshi: 0,
            checkisabled: true,
            Subscriber: false,
            purchaseDialog2: false,
            input: 0,
            discount: 0,
            productList: [],
            total: '',
            defProduct: {
                num: 0,
                actualPrice: 0.0,
                preferentialText: '',
                no: '',
            },

            companySizeCheckList3: ['Search Company', 'Search Product', 'Search Trade', 'Search People', 'Search Country', 'Search News', 'Data Services', 'Volume Discounts ', 'API Plans', 'Group Accounts', 'Customized Plans'],

            companySizeCheckList4: ['Search Company', 'Search Product', 'Search Trade', 'Search People', 'Search Country', 'Search News', 'Data Services', 'Volume Discounts ', 'API Plans', 'Group Accounts', 'Customized Plans'],
            companySizeCheckList: [],
            companySizeCheckList1: [],
            companySizeCheckList2: [],
            checkcompanySizeCheckList: '',
            disDefault: true,
            purchaseDialog: false,
            isNew: false,
            Num: [],
            purchaseForm: {
                price: '',
                loginAccount: '',
                fullName: '',
                cardNumber: '',
                expMonth: '',
                expYear: '',
                cardCode: '',
                country: '',
                province: '',
                zip: '',
            },

            iconlist: [
                {
                    title: 'Search companies in 200+ countries',
                    icon: require('@/assets/img/addcreate/iconsousuo.png'),
                },
                {
                    title: 'Verify company legal status',
                    icon: require('@/assets/img/addcreate/yanzhengren.png'),
                },
                {
                    title: 'Understand company ownership structure',
                    icon: require('@/assets/img/addcreate/zuzhijiagou.png'),
                },
                {
                    title: 'Obtain company’s contact information',
                    icon: require('@/assets/img/addcreate/lianxifangshi.png'),
                },
                {
                    title: 'Understand company’s financial performance',
                    icon: require('@/assets/img/addcreate/caiwu.png'),
                },
                {
                    title: 'Check company’s news and sanction records',
                    icon: require('@/assets/img/addcreate/news 1.png'),
                },
                {
                    title: 'Check company’s local credit assessment',
                    icon: require('@/assets/img/addcreate/xinyongpingjia.png'),
                },
                {
                    title: 'Find out company’s available trade records',
                    icon: require('@/assets/img/addcreate/jiaoyijilu.png'),
                },
                {
                    title: 'Compare companies',
                    icon: require('@/assets/img/addcreate/tandem.png'),
                },
                {
                    title: 'Download company’s online reports',
                    icon: require('@/assets/img/addcreate/baogaoxiazai.png'),
                },
                {
                    title: 'Obtain fresh updated credit reports',
                    icon: require('@/assets/img/addcreate/icon_xinyong_xianxing_jijin-213.png'),
                },
                {
                    title: 'Group accounts for multiple users in the same organization',
                    icon: require('@/assets/img/addcreate/yonghufenzu.png'),
                },
                {
                    title: 'Data plans and API services',
                    icon: require('@/assets/img/addcreate/shujuwajue.png'),
                },
            ],
            iconlist1: [
                {
                    title: 'Search products or services',
                    icon: require('@/assets/img/addcreate/chanpin.png'),
                },
                {
                    title: 'Find potential customers or suppliers',
                    icon: require('@/assets/img/addcreate/gongyingshang.png'),
                },
                {
                    title: 'Understand market statistics',
                    icon: require('@/assets/img/addcreate/jingjizhibiao.png'),
                },
                {
                    title: 'Obtain more target recommendation list notification services',
                    icon: require('@/assets/img/addcreate/xiaoxitongzhi.png'),
                },
                {
                    title: 'Compare companies',
                    icon: require('@/assets/img/addcreate/tandem.png'),
                },
                {
                    title: 'Export data',
                    icon: require('@/assets/img/addcreate/shujudaochu.png'),
                },
            ],
            iconlist2: [
                {
                    title: 'Search import & export information',
                    icon: require('@/assets/img/addcreate/daoru.png'),
                },
                {
                    title: 'Find potential customers or suppliers',
                    icon: require('@/assets/img/addcreate/gongyingshang.png'),
                },
                {
                    title: 'Understand market statistics',
                    icon: require('@/assets/img/addcreate/jingjizhibiao.png'),
                },
                {
                    title: 'Obtain more target recommendation list notification services',
                    icon: require('@/assets/img/addcreate/xiaoxitongzhi.png'),
                },
                {
                    title: 'Compare companies',
                    icon: require('@/assets/img/addcreate/tandem.png'),
                },
                {
                    title: 'Export data',
                    icon: require('@/assets/img/addcreate/shujudaochu.png'),
                },
            ],
            iconlist3: [
                {
                    title: 'Search business contacts',
                    icon: require('@/assets/img/addcreate/cylxr.png'),
                },
                {
                    title: 'View business connections and relations',
                    icon: require('@/assets/img/addcreate/yewuliu.png'),
                },
                {
                    title: 'Find target companies',
                    icon: require('@/assets/img/addcreate/iconsousuo.png'),
                },
                {
                    title: 'Export data',
                    icon: require('@/assets/img/addcreate/shujudaochu.png'),
                },
            ],
            iconlist4: [
                {
                    title: 'Search country profile',
                    icon: require('@/assets/img/addcreate/diqufenxi.png'),
                },
                {
                    title: 'Check country’s major economic indicators',
                    icon: require('@/assets/img/addcreate/jingjizhibiao.png'),
                },
                {
                    title: 'Check country’s trade data',
                    icon: require('@/assets/img/addcreate/maoyi-.png'),
                },
                {
                    title: 'Understand country’s business environment',
                    icon: require('@/assets/img/addcreate/huanjingjiance.png'),
                },
                {
                    title: 'View country’s ratings',
                    icon: require('@/assets/img/addcreate/icon_xinyong_xianxing_jijin-271.png'),
                },
                {
                    title: 'Compare countries',
                    icon: require('@/assets/img/addcreate/guojiaguanliicon-.png'),
                },
            ],
            iconlist5: [
                {
                    title: 'Search company news',
                    icon: require('@/assets/img/addcreate/news 1.png'),
                },
                {
                    title: 'Check sanction list from different countries',
                    icon: require('@/assets/img/addcreate/yuangongchengfachufachengfa.png'),
                },
                {
                    title: 'Export data',
                    icon: require('@/assets/img/addcreate/shujudaochu.png'),
                },
            ],
            purchaseRules: {
                fullName: [{ required: true, message: 'Incorrect Full Name format', trigger: 'blur' }],
                cardNumber: [{ required: true, message: 'Incorrect Credit Card Number format', trigger: 'blur' }],
                expMonth: [{ required: true, message: 'Incorrect Exp.Month format', trigger: 'change' }],
                expYear: [{ required: true, message: 'Incorrect Exp.Year format', trigger: 'change' }],
                cardCode: [{ required: true, message: 'Incorrect CVC Code format', trigger: 'blur' }],
                country: [{ required: true, message: 'Incorrect Country format', trigger: 'change' }],
                province: [{ required: true, message: 'Incorrect Province / State format', trigger: 'change' }],
                zip: [{ required: true, message: 'Incorrect Postal Code format', trigger: 'blur' }],
            },
            proviceArrLoading: false,
            yearOptions: [],
            monthOptions: [
                { laber: '1', value: '01' },
                { laber: '2', value: '02' },
                { laber: '3', value: '03' },
                { laber: '4', value: '04' },
                { laber: '5', value: '05' },
                { laber: '6', value: '06' },
                { laber: '7', value: '07' },
                { laber: '8', value: '08' },
                { laber: '9', value: '09' },
                { laber: '10', value: '10' },

                { laber: '11', value: '11' },

                { laber: '12', value: '12' },
            ],
            countryOptions: [],
            provinceOptions: [],
            apiLoginID: '',
            clientKey: '',
            orderId: '',
            userBalance: 0,
            checkshow: false,
        };
    },
    // 支付逻辑 首先通过add接口和getPublicClientKey 获取：clientKey，apiLoginID orderId 等参数 
    // 2然后 拿到authData.clientKey = this.clientKey; authData.apiLoginID = this.apiLoginID;
    // 3提交表单 验证通过返回信息datavalue和orderId 调取cardAuthorize支付接口
    mounted() {
        this.getYears();
        this.getCountry();

        // 引入外部js文件 acept.js 脚本已经加载，并且 Accept 对象可用 支付环境加载
        // const scriptElement = createScript('https://jstest.authorize.net/v1/Accept.js');// dev 环境
        const scriptElement = createScript('https://js.authorize.net/v1/Accept.js'); // prod 环境
        scriptElement.onload = function () {
            // 输出xxx.js中的xxx
            //console.log(window.Accept.dispatchData);
        };
    },
    created() {
        this.getRecharge();

        // this.getordersAdd()
        // this.getPCKey()
        // this.postCardAuthorize({})
    },
    methods: {
        tiaokuan() {
            bus.$emit('Agreement', true);
        },
        show(val) {
            this.zhanshi = val;
        },
        menucheck(val) {
            // console.log(`this.`, this.companySizeCheckList);
        },
        chckenum(val) {
            this.checkshow = true;
            let chenckboxlist = this.companySizeCheckList2;
            if (chenckboxlist.includes(val.code)) {
                this.Num.push(val.price);
            } else {
                this.Num.splice(this.Num.indexOf(val.price), 1);
            }
            if (this.Num.length > 0) {
                this.checkisabled = false;
            } else {
                this.checkshow = false;
                this.checkisabled = true;
            }

            this.productList[2].actualPrice = Math.round(this.Num.reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 1);
        },
        // 输入计算值
        inputnum() {
            if (this.input != '' && this.input >= 0) {
                this.input = parseInt(this.input);
                this.getPriceData();
            }
        },
        /**
         *获取充值画面 ok
         */

        getRecharge() {
            let params = {
                // actualPrice: 500,
            };
            getList(params).then(result => {
                let data = result;

                let isNew = data.data.userInfo.isNew;
                if (isNew == '1') {
                    this.isNew = true;
                    // this.isNew = false;
                } else {
                    this.isNew = false;
                    // this.isNew = true ;
                }
                if (data && data.code && data.data) {
                    let userBalance = data.data.userBalance.integral;
                    if (userBalance == 0) {
                        this.$message({
                            message: 'The current balance is 0, please recharge',
                            center: true,
                            type: 'warning',
                        });
                    }
                    this.companySizeCheckList = data.data.productList[0].producDetailCodes.split(',');
                    this.companySizeCheckList1 = data.data.productList[1].producDetailCodes.split(',');
                    this.companySizeCheckList3 = data.data.productList[3].producDetailCodes.split(',');
                    this.companySizeCheckList4 = data.data.productList[3].productDetailList;

                    this.companySizeCheckList2 = data.data.productList[2].productDetailcods == null ? (data.data.productList[2].productDetailcods = ['10014', '7', '1']) : data.data.productList[2].productDetailcods;
                    // [...data.data.productList[2].productDetailcods,'10016']

                    // data.data.productList[0].num = 30;
                    // data.data.productList[1].num = 500;
                    // data.data.productList[0].pice = 180;

                    // data.data.productList[1].price = this.input * 10;
                    // let num = this.input * 10;
                    // data.data.productList[1].pice = num * 0.8;
                    // data.data.productList[2].pice = 0;
                    // data.data.productList[0].price = 300;

                    // data.data.productList[0].preferentialText = 'for new user only';
                    // data.data.productList[1].preferentialText = 'for limited time offer';
                    // data.data.productList[2].preferentialText = 'for limited time offer';
                    // data.data.productList[2].name = 'Subscriber';

                    // data.data.productList[2].num=
                    this.productList = data.data.productList;
                    this.defProduct = data.data.productList[1];
                    this.defProduct.num = this.defProduct.num ? this.defProduct.num : 0;

                    this.defProduct.actualPrice = this.defProduct.actualPrice ? this.defProduct.actualPrice : 0.0;
                }
            });
        },

        /**
         *自定义点数， 获取价格
         */
        getPriceData() {
            let params = {
                no: this.defProduct.no,
                num: this.input,
            };
            getPrice(params).then(result => {
                let data = result;
                if (data && data.code && data.data) {
                    let pircelist = this.defProduct.productDetailList;
                    this.defProduct.actualPrice = data.data;

                    pircelist.map(item => {
                        if (item.type == '1') {
                            this.discount = item.discount;
                        }
                    });
                    this.productList[1].price = parseFloat(this.defProduct.actualPrice);
                    this.defProduct.actualPrice = this.productList[1].price * (1 - this.discount);
                    this.disDefault = false;
                } else {
                    // this.$message({
                    //     message: data.msg,
                    //     center: true,
                    //     type: 'warning',
                    // });
                    this.disDefault = true;
                    this.defProduct.num = 0;
                    this.defProduct.actualPrice = 0.0;
                }
            });
        },
        //购买弹窗
        payNow(obj, index, isindex) {
            let userObj = JSON.parse(sessionStorage.getItem('userInfo')) == null ? JSON.parse(localStorage.getItem('userInfo')) : JSON.parse(sessionStorage.getItem('userInfo'));
            if (index == 0) {
                this.checkcompanySizeCheckList = obj.producDetailCodes;
                this.Subscriber = false;
                obj.actualPrice = 180;
                this.purchaseDialog = true;
            }
            if (index == 1) {
                this.checkcompanySizeCheckList = obj.producDetailCodes;
                this.Subscriber = false;
                obj.num = Number(this.input);
                // obj.actualPrice = this.productList[1].pice;
            }
            if (index == 2) {
                obj.num = Number(this.input);
                this.checkcompanySizeCheckList = this.companySizeCheckList2.join(',');
                this.Subscriber = true;
                if (isindex) {
                    this.getOrdersAddPro(obj);
                    this.purchaseDialog = true;
                } else {
                    this.purchaseDialog2 = true;
                }

                // obj.actualPrice= obj.actualPrice+this.productList[1].actualPrice

                // obj.actualPrice = this.productList[2].pice;
            }

            this.purchaseForm.price = obj.actualPrice;
            this.purchaseForm.loginAccount = userObj.userInfo.loginName;
            //获取key
            this.getPCKey();
            //用户购买报告

            if (index == 4) {
                this.purchaseDialog = true;
                this.getOrdersAddDef(obj.no);
            } else {
                if (index == 1) {
                    if (this.input > 0) {
                        this.purchaseDialog = true;
                        this.getOrdersAddPro(obj);
                    } else {
                        this.$message({
                            message: 'Please enter the purchase quantity',
                            center: true,
                            type: 'warning',
                        });
                    }
                } else {
                    if (index != 2) {
                        this.getOrdersAddPro(obj);
                    }
                }
            }
        },
        /** step 1-1
         *用户购买报告  3.1 选择产品
         */
        getOrdersAddPro(obj) {
            let params = {
                method: '3',
                type: 3,
                num: obj.num,
                productId: obj.no, // 产品编号 no
                amount: obj.name == 'Subscriber' ? obj.actualPrice + this.productList[1].actualPrice : obj.actualPrice,
                productDetailCodes: this.checkcompanySizeCheckList,
            };
            ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code && data.data) {
                    this.orderId = data.data.id;
                }
            });
        },
        /** step 1-1
         *用户购买报告  3.2 自定义数量
         */
        getOrdersAddDef(no) {
            let params = {
                method: '3',
                type: 3,
                amount: this.purchaseForm.price,
                num: this.defProduct.num,
                productId: no,
            };
            ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code && data.data) {
                    this.orderId = data.data.id;
                }
            });
        },
        /** step 2
         *获取PublicClientKey：clientKey，apiLoginID
         */
        getPCKey() {
            getPublicClientKey().then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                if (data && data.code && data.data) {
                    this.apiLoginID = data.data.apiLoginID;
                    this.clientKey = data.data.clientKey;
                }
            });
        },
        /** step 4
         * cardAuthorize
         */
        postCardAuthorize(opaqueData) {
            let params = {
                dataValue: opaqueData.dataValue, //必须
                dataDescriptor: opaqueData.dataDescriptor, //必须
                orderId: this.orderId, //必须
            };
            // const params = new URLSearchParams();
            // params.append('dataValue', opaqueData.dataValue);
            // params.append('dataDescriptor', opaqueData.dataDescriptor);
            // params.append('orderId', this.orderId);
            cardAuthorize(params).then(result => {
                // let data = JSON.parse(crypto.decrypt(result));
                if (result.code) {
                    this.$message({
                        message: result.msg,
                        center: true,
                        type: 'success',
                    });
                    this.purchaseDialog = false;
                    this.$router.push({
                        path: '/account/billing',
                    });
                } else {
                    this.$message({
                        message: result.msg,
                        center: true,
                        type: 'error',
                    });
                }
            });
        },

        //获取年 2022-2033
        getYears() {
            const loadYear = new Date().getFullYear() - 1;
            const n = 11;
            for (var i = 0; i < n; i++) {
                const year = loadYear + i;
                this.yearOptions.push(year.toString());
            }
            return this.yearOptions;
        },
        //获取国家
        getCountry() {
            this.countryOptions = JSON.parse(localStorage.getItem('countryList'))[0].countryList;
        },
        changeCountry() {
            this.purchaseForm.province = '';
            this.provinceOptions = [];
            if (this.purchaseForm.country) {
                this.getProvince();
            }
        },
        //获取省市
        async getProvince() {
            this.proviceArrLoading = true;
            const params = {
                page: 1,
                pageSize: 10,
                company_country: this.purchaseForm.country,
            };
            await getoptions(params).then(res => {
                if (res && res.code) {
                    this.proviceArrLoading = false;
                    let list = res.data.geographicalLocation.length > 0 ? res.data.geographicalLocation[0].companyProvince : [];
                    this.provinceOptions = list;
                }
            });
        },
        /** step 3
         *获取Accept： dataValue，dataDescriptor
         *提交表单
         */
        onSubmit() {
            this.$refs.purchaseForm.validate(async valid => {
                if (valid) {
                    let authData = {};
                    authData.clientKey = this.clientKey;
                    authData.apiLoginID = this.apiLoginID;

                    let cardData = {};
                    cardData.fullName = this.purchaseForm.fullName;
                    cardData.cardNumber = this.purchaseForm.cardNumber;
                    cardData.month = this.purchaseForm.expMonth;
                    cardData.year = this.purchaseForm.expYear;
                    cardData.cardCode = this.purchaseForm.cardCode;

                    var secureData = {};
                    secureData.authData = authData;
                    secureData.cardData = cardData;

                    let lastResponse = null;
                    const responseHandler = response => {
                        try {
                            // just in case it still gets called twice, ignore the second call
                            if (response == lastResponse) {
                                // console.log("skipping duplicated responseHandler() call because it's the same response as the previous response");
                                return;
                            }
                            lastResponse = response;
                            if (response.messages.resultCode === 'Error') {
                                for (let i = 0; i < response.messages.message.length; i++) {
                                    this.$message({
                                        message: response.messages.message[i].code + ': ' + response.messages.message[i].text,
                                        type: 'error',
                                        duration: 3000,
                                    });
                                }
                            } else {
                                this.postCardAuthorize(response.opaqueData);
                            }
                        } catch (error) {
                            console.log(error, '支付报错');
                        }
                    };

                    console.log(secureData, 'secureData 支付提交的表单信息->');
                    // Accept.dispatchData(secureData, responseHandler);
                    // window.Accept.dispatchData 方法将该对象提交给支付网关进行支付
                    // 如果支付成功，则会调用 postCardAuthorize
                    window.Accept.dispatchData(secureData, responseHandler);
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //取消
        btnCancel() {
            this.purchaseDialog = false;
            this.purchaseForm.price = '';
            this.clientKey = '';
            this.apiLoginID = '';
            this.clientKey = '';
            this.orderId = '';
        },
    },
};
</script>
<style scoped>
.history_box {
    margin-top: 22px;
}
.cre_title {
    line-height: 36px;
    font-size: 28px;
    font-family: 'Arial Bold';
    color: #022955;
    margin: 30px 0;
    text-align: center;
}
.sub_title2,
.sub_title {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 18px;
}
.sub_title2 img,
.sub_title img {
    width: 20px;
    height: 18px;
    margin-right: 8px;
    margin-bottom: 40px;
}
.sub_title2 img {
    margin-bottom: 20px;
}
.price_box {
    margin-bottom: 60px;
    display: flex;
    align-items: center;
}
.price_item {
    position: relative;
    /* padding: 20px; */
    /* width: 280px; */
    width: 354px;
    /* padding-bottom: 20px; */
    /* height: 288px; */
    background: #f7f8fa;
    border-radius: 0 0 14px 14px;
}
.price_item_ic {
    width: 20px;
    height: 20px;
}
.price_unit {
    position: absolute;
    right: 20px;
    top: -8px;
    width: 88px;
    height: 80px;
    font-size: 26px;
    font-family: 'Arial Bold';
    color: #ffffff;
    line-height: 26px;
    padding: 17px 3px 22px;
}
.units_bg_0 {
    /* background-image: url(../../assets/img/detail/creadit_bg0.png);
    background-repeat: no-repeat; */
    background-size: cover;
    background-color: #55b419;
}
.name_0 {
    background-color: #55b419;
    border-radius: 14px 14px 0 0;
    color: white;
    font-size: 25px;
}
.units_bg_1 {
    background-image: url(../../assets/img/detail/creadit_bg1.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.name_1 {
    background-color: rgba(255, 192, 0);
    border-radius: 14px 14px 0 0;
    color: white;
    font-size: 25px;
}
.units_bg_2 {
    background-image: url(../../assets/img/detail/creadit_bg2.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.name_2 {
    background-color: rgba(237, 125, 49);
    border-radius: 14px 14px 0 0;
    color: white;
    font-size: 25px;
}
.units_bg_3 {
    background-image: url(../../assets/img/detail/creadit_bg3.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.name_3 {
    /* background-color: rgba(0, 112, 192); */
    background-color: #1290c9;
    border-radius: 14px 14px 0 0;
    color: white;
    font-size: 25px;
}
.price_unit_num {
    width: 74px;
    font-size: 26px;
    font-family: 'Arial Bold';
    color: #ffffff;
    line-height: 26px;
    text-align: center;
    margin-left: 8px;
}
.num_border /deep/ .el-input__inner {
    width: 74px;
    height: 26px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 0 10px;
    text-align: center;
}
.inputttext /deep/ .el-input__inner:focus {
    border-color: transparent;
}
.inputttext /deep/ .el-input__inner {
    border-width: 2px;
    border-color: rgba(255, 192, 0);
}
.price_unit_tip {
    font-size: 12px;
    color: #ffffff;
    line-height: 14px;
    text-align: center;
}
.price {
    font-size: 30px;
    font-family: 'Arial Bold';
    color: #022955;
    /* margin-top: 45px; */
    margin-bottom: 8px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.price_eldio {
    font-size: 30px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-top: 15px !important;
    margin-bottom: 8px;
    height: 34px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
}
.price span {
    margin-right: 8px;
}
.actual_price {
    font-size: 14px;
    color: #8497ab;
    line-height: 16px;
    text-decoration: line-through;
    /* margin-bottom: 16px; */
}
.limit_txt {
    height: 30px;
    font-size: 14px;
    color: #8497ab;
    line-height: 20px;
    font-style: italic;
    /* margin-bottom: 16px; */
}
.button_box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: #ccc;
    border-radius: 0 0 14px 14px;
}
.btn_buy {
    width: 190px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 12px;
    color: #ffffff;
    cursor: pointer;

    margin: auto;
    /* position: absolute;
    bottom: 20px;
    left: 45px; */
}
.btn_buytwo {
    width: 190px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 12px;
    color: #ffffff;
    cursor: pointer;
    /* position: absolute;
    bottom: 20px;
    left: 45px; */
}
.buy_0 {
    background: #55b419;
}
.buy_1 {
    background-color: rgba(255, 192, 0);
}
.buy_us {
    background-color: #1290c9;
}
.buy_2 {
    background-color: rgba(237, 125, 49);
}
.buy_3 {
    background: #022955;
    margin-top: 48px;
}
.buy_3:disabled {
    background: linear-gradient(135deg, #8cd7ff, #83c6ea);
    cursor: not-allowed;
}
.btn_buy img,
.btn_buytwo img {
    margin-right: 8px;
    width: 18px;
    height: 16px;
}
.price_des {
    /* width: 280px; */
    width: 350px;
    height: 700px;
    padding: 15px 8px;
    /* background: #f7f8fa; */
    /* border-radius: 0px 0px 24px 24px; */
    line-height: 16px;
    font-size: 14px;
    /* margin-top: 54px; */
}
.price_desc {
    margin-top: 50px;
    padding: 10px;
    padding-bottom: 30px;
    background: #f7f8fa;
    /* margin-bottom: 20px; */
    /* border-radius: 0px 0px 24px 24px; */
    line-height: 16px;
    font-size: 14px;
    /* margin-top: 54px; */
}
.des_3 {
    /* margin-top: 70px !important; */
    position: relative;
}
.price_item_box {
    border: 1px solid #55b419;
    border-radius: 14px;
    border-top: none;
}
.price_item_box1 {
    border: 1px solid rgba(255, 192, 0);
    border-top: none;
    border-radius: 14px;
}
.price_item_box2 {
    border: 1px solid rgba(237, 125, 49);
    border-top: none;
    border-radius: 14px;
}
.price_item_box3 {
    border: 1px solid #1290c9;
    border-top: none;
    border-radius: 14px;
}

.des_0 {
    color: #55b419;
    position: relative;
}
.des_1 {
    color: #1290c9;
    position: relative;
}
.des_2 {
    color: #ff7600;
    position: relative;
}
.des_2c {
    color: #ff7600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cre_tbl {
    width: 100%;
    height: 40px;
    padding: 10px 0;
    background: #f2f5f9;
    border-radius: 14px 14px 0px 0px;
    border: 1px solid #e7eaed;
}
.tbl_ic {
    color: #8497ab;
    line-height: 16px;
    font-size: 14px;
}
.tbl_ic i {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
}
.tbl_ic i.green {
    background: #55b419;
}
.tbl_ic i.blue {
    background: #1290c9;
}
.tbl_ic + .tbl_ic {
    margin-left: 30px;
}
.csi_title {
    width: 205px;
    line-height: 20px;
    font-size: 14px;
    color: #8497ab;
    text-align: center;
}
.csi_title.lg {
    width: 520px;
    padding-left: 175px;
}
.tbl_item_uni {
    width: 207px;
    height: 60px;
}
.tbl_item_uni i {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #e7eaed;
}
.tbl_item_uni i + i {
    margin-left: 20px;
}
.tbl_item_uni .green {
    border: 1px solid #55b419;
}
.tbl_item_uni .blue::after,
.tbl_item_uni .green::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
}
.tbl_item_uni .green::after {
    background-color: #55b419;
}
.tbl_item_uni .blue::after {
    background-color: #1290c9;
}
.tbl_item_uni .blue {
    border: 1px solid #1290c9;
}
.tbl_item_title {
    width: 145px;
}
.tbl_item_subtitle {
    width: 373px;
}
.tbl_item_uni,
.tbl_item_subtitle,
.tbl_item_title {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-left: 1px solid #e7eaed;
    border-bottom: 1px solid #e7eaed;
}
.tbl_item {
    border-right: 1px solid #e7eaed;
}

.fin_title {
    font-family: 'Arial Bold';
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 20px;
    color: #022955;
    text-align: center;
}
.form_box {
    margin-bottom: 10px;
}
.form_box /deep/ .el-input__inner {
    width: 222px;
    height: 32px;
    line-height: 32px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.form_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
}
.form_box /deep/ .el-form-item__content {
    line-height: 32px;
}
.form_box /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    height: 35px;
    line-height: 16px;
    color: #022955 !important;
    padding-right: 10px;
}

.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
}
.btn_box {
    text-align: right;
}
.purc_left {
    width: 345px;
    height: 620px;
    background: #f7f8fa;
    border-radius: 14px;
    padding: 20px;
}
.purc_right {
    margin-left: 10px;
    width: 750px;
    height: 620px;
    background: #f7f8fa;
    border-radius: 14px;
    padding: 20px 20px 30px;
}
.m_b_20 {
    margin-bottom: 20px;
}
.m_b_40 {
    margin-bottom: 40px;
}
.m_l_20 {
    margin-left: 20px;
}
.pay_price {
    line-height: 40px;
    font-size: 30px;
    font-family: Arial Bold;
    color: #022955;
}
.pay_left_ul li,
.pay_txt {
    font-size: 14px;
    color: #333333;
}
.pay_left_ul li {
    display: flex;
    margin-bottom: 10px;
    word-break: break-word;
}
.pay_left_ul .el-icon-success {
    color: #1290c9;
    margin-right: 10px;
    font-size: 18px;
}
.pay_left_sub_title {
    font-family: Arial Bold;
    font-size: 14px;
    color: #022955;
    margin-bottom: 10px;
    margin-top: 20px;
}
.acc_log,
.acc_right {
    font-family: Arial Bold;
    font-size: 16px;
    color: #022955;
    margin-bottom: 30px;
}
.total_txt,
.pay_title {
    color: #022955;
    font-size: 16px;
}

.tip_txt {
    color: #022955;
    font-size: 14px;
}
@media (max-width: 821px) {
    .phone_flex_box {
        flex-wrap: wrap;
    }
    .m_l_20 {
        margin-left: 0px !important;
    }
    .purc_left {
        width: 100% !important;
        height: auto !important;
    }
    .purc_right {
        width: 100%;
        height: auto !important;
        margin: 0px !important;
    }
    .icon-text:nth-last-of-type(1) {
        margin-right: 0px !important;
    }
    .price_des {
          width: 100% !important;
        height: auto !important;
    }
    .price_des .coll_child_name {
        display: none;
    }
    .price_item_box3 {
        display: none;
    }
    .icon-text {
        margin-right: 0px !important;
    }
    .card_img {
        width: 46% !important;
        margin: 25px 0px !important;
    }
    .icon-text-box1 {
        font-size: 14px !important;
    }
    .icone {
        height: 50px !important;
        width: 50px !important;
    }
    .card_img1_sanjiao,
    .card_img1_sanjiao1,
    .card_img1_sanjiao2,
    .card_img1_sanjiao3,
    .card_img1_sanjiao4,
    .card_img1_sanjiao5 {
        width: 100% !important;
    }
    .cre_title {
        font-size: 16px !important;
    }
    .companyservices {
        font-size: 22px !important;
    }

    .das_top_item {
        padding: 10px;
        border-radius: 14px;
        background: #ffffff;
        display: flex;
        align-items: center;
        margin: 10px 0px;
        justify-content: start;
        width: 49%;
        box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    }

    .das_top_item .item_img {
        width: 25px;
        height: 25px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
    }
    .das_top_item img {
        height: 14px;
        width: 14px;
    }
    .das_top_item .color1 {
        background: #1290c9;
    }

    .das_top_item .color2 {
        background: #f56b6b;
    }

    .das_top_item .color3 {
        background: #55b419;
    }

    .das_top_item .color4 {
        background: rgb(255, 192, 0);
    }
    .das_top_item .color5 {
        background: cyan;
    }
    .das_top_item .color6 {
        background: cornflowerblue;
    }

    .das_top_item .item_title {
        font-size: 18px;
        color: #022955;
        margin: 10px 0 10px;
        text-align: center;
    }
    .das_top_item .item_group {
        margin-left: 5px;
        font-size: 12px;
    }
    .das_top_item .item_lab {
        display: block;
        color: #8497ab;
        /* margin-bottom: 8px; */
        /* width: 180px; */
        margin-top: 5px;
    }
    .item_txt {
        font-size: 12px;
    }
    .phone_addcreade {
        width: 100% !important;
    }
     .phone_addcreade .price_box .price_item_box1 .price_item_titel,
    .phone_addcreade .price_box .price_item_box2 .price_item_titel,
    .phone_addcreade .price_box .price_item_box .price_item_titel {
        height: 36px;
        width: 45%;
        margin: auto;
        position: relative;
        top: -20px;
        font-size: 16px !important;
        border-radius: 20px !important;
    }
    .phone_addcreade .price_box .price_item_box {
        border-top: 1px solid #55b419 !important;
    }
    .phone_addcreade .price_box .price_item_box .price_des {
        width: 100% !important;
        padding: 5px !important;
    }
    .add_price_des li {
        list-style: disc;
        margin-left: 20px;
        font-size: 12px;
        color: #022955;
    }
    .phone_addcreade .price_box .price_item_box .price_item {
        width: 100% !important;
    }
    .phone_addcreade .price_box .price_item_box .price_item .price_item_bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .price {
        font-size: 18px !important;
        margin-bottom: 0px !important;
        height: auto !important;
    }
    .inputttext2 {
        font-size: 18px !important;
    }
    .price_item_box2 {
        width: 100%;
   
            border-top: 1px solid rgba(237, 125, 49);
        margin-top: 50px;
    }
     .price_item_box1 {
        width: 100%;
         border-top: 1px solid rgba(255, 192, 0);
        margin-top: 50px;
    }
     .price_item {
        width: 100% !important;
    } 
    .price_item_box2  .price_des /deep/ .el-checkbox__input {
        position: absolute;
    right: 0px !important;
    }
    .inputprive{
        top: 0px !important;
    }
    .price_item_box1 {
        width: 100% !important;
    }
     .price_item_box1 .price_item_bottom {
        margin-top: 10px ;
        padding: 10px !important;
     }
     .flexprice{
        display: flex;
        justify-content: space-between;
     }
    .price_item_box1 .inputttext /deep/ .el-input__inner {
        font-size: 16px !important;
         width: 55px !important;
            padding: 5px  !important;
     }
     .price_item_box1  .inputtitle .el-input {
        width: 55px !important;
     
     }
      .price_item_box1 .price{
     font-size: 16px !important;
      }
      .button_box{
        height: 60px !important;
      }
      .btn_buy {
            height: 36px !important;
    width: 45% !important;
        border-radius: 20px !important;
      }
      .sub_title img {
        margin-bottom: 30px !important;
      }
  
}
</style>
<style >
.puy_dialog .el-dialog__body {
    padding: 0;
}
.inpt_line .el-input__icon {
    line-height: 32px;
}
.price_des_text {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
}
.duihao {
    height: 20px;
    width: 20px;
    text-align: center;
    /* border: 2px solid #55b419;
    color: #55b419; */
    line-height: 20px;
}
.price_item_bottom {
    padding: 10px 10px;
    padding-bottom: 0px;
    text-align: center;
}
.price_item_titel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
}
.price_itemtwo {
    border-radius: 14px !important;
}
.inputtitle {
    /* float: left; */
    margin-top: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 40px; */
}
.inputtitle .el-input {
    width: 105px;
}
.inputttext .el-input__inner {
    width: 100px !important;
    margin-right: 0px;
    height: 40px;
    font-size: 30px;
}
.inputtitle2 {
    margin-top: -10px;
    display: flex;
    align-items: center;
}

.inputttext2 {
    /* margin-left: -60px;
    width: 105px; */
    font-size: 30px;
    text-align: right;
    line-height: 40px;
    /* margin-right: 8px; */
    height: 40px;
}
.inputtitle2 .el-input {
    width: 120px;
}
.inputttext .el-input__inner {
    text-align: right;
}

.inputttext .el-input__inner::-webkit-outer-spin-button,
.inputttext .el-input__inner::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
.inputttext .el-input__inner[type='number'] {
    -moz-appearance: textfield;
}
.price_des .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    border: 1px solid #8497ab;
}
.price_des .el-checkbox {
    display: flex;
    margin-bottom: 5px;
    margin-right: 0px;
}
.price_des .el-checkbox__input {
    position: absolute;
    right: 0;
}
.price_des .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #ff7600;
    border: #ff7600;
}
.price_des .el-checkbox__inner::after {
    /* position: absolute;
left: 7px;
top: 4px; */
    width: 14px;
    height: 10px;
    left: 3px;
    top: 5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAAAAXNSR0IArs4c6QAAAdtJREFUSEu1lFuLzWEUxn+Pi3Fohm/gU3ChKc0wGYcko7mZK+XChSSKoiiKjHIIcTHlKwgjh0wpERkiJSmXSM7nw82jpXe0zOxt9t7t/7pc71rPb73r/z5/UXHYngEcBIaAYVXJsx36Z4D1hfOiMmCBjQAb0qVOVAIssNPAxgQ7BwxWBTwJbEqwUWCdpF9tB9o+BmxJsEvAWkk/I9dWoO3DwLYEuwqskfRjItc2oO1hYEeCjQGrJX3PTpDt+bFf4LykZ63YxPZ+YFfqvQ6skvRtsl4A7wILgC/Ackk3m4Ha3gvsST03gBWSvtbSycA4/wwsk3S7Eajt3cC+VHsL6JcUw9eMAC4ErgFzS8UnoE9S3Lxu2N4JHEgFd8qw0V83/jwa24uAK0BXqfxQoPdqddreDhxKZ+Ol/uN0m/n7Sm13A5eBztL0Dlgq6UEWsb0VOJJy9wvs/XSwOP/HFrYXA2HUOaX5LdAr6VHZxGbgeBJ+CCyRFMM1FFN8aLsXuAjMLgqvAwrEMKeSagwRsDcNkUpRTePb7gMuALPSN52XNvIY6JEUwzQVdf80tvuB+MPPnKT4pMBeNUX63w0nhGyvBM4CHSX3tMBetgKb8mjqWCCgR4GwyoCk563Cou83IHee8sLkMbEAAAAASUVORK5CYII=);
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(0deg) scaleY(1) !important;
    border: none;
}
.check-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
    border: 1px solid #8497ab;
    background-color: #55b419;
    border: #55b419;
}
.check-boxwihte {
    border: 1px solid #8497ab;
    background-color: #ffffff;
    /* border: #ffffff; */
}
.check-box1 {
    background-color: rgba(255, 192, 0);
    border: rgba(255, 192, 0);
}
.check-box2 {
    background-color: #ff7600;
    border: #ff7600;
}
.check-box .check-son {
    position: absolute;
    width: 14px;
    height: 10px;
    left: 3px;
    top: 5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAUCAYAAACeXl35AAAAAXNSR0IArs4c6QAAAdtJREFUSEu1lFuLzWEUxn+Pi3Fohm/gU3ChKc0wGYcko7mZK+XChSSKoiiKjHIIcTHlKwgjh0wpERkiJSmXSM7nw82jpXe0zOxt9t7t/7pc71rPb73r/z5/UXHYngEcBIaAYVXJsx36Z4D1hfOiMmCBjQAb0qVOVAIssNPAxgQ7BwxWBTwJbEqwUWCdpF9tB9o+BmxJsEvAWkk/I9dWoO3DwLYEuwqskfRjItc2oO1hYEeCjQGrJX3PTpDt+bFf4LykZ63YxPZ+YFfqvQ6skvRtsl4A7wILgC/Ackk3m4Ha3gvsST03gBWSvtbSycA4/wwsk3S7Eajt3cC+VHsL6JcUw9eMAC4ErgFzS8UnoE9S3Lxu2N4JHEgFd8qw0V83/jwa24uAK0BXqfxQoPdqddreDhxKZ+Ol/uN0m/n7Sm13A5eBztL0Dlgq6UEWsb0VOJJy9wvs/XSwOP/HFrYXA2HUOaX5LdAr6VHZxGbgeBJ+CCyRFMM1FFN8aLsXuAjMLgqvAwrEMKeSagwRsDcNkUpRTePb7gMuALPSN52XNvIY6JEUwzQVdf80tvuB+MPPnKT4pMBeNUX63w0nhGyvBM4CHSX3tMBetgKb8mjqWCCgR4GwyoCk563Cou83IHee8sLkMbEAAAAASUVORK5CYII=);
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(0deg) scaleY(1) !important;
    border: none;
}
.price_des .el-checkbox__input.is-focus .el-checkbox__inner {
    /* background-color: #022955; */
    border-color: #022955;
}
.price_des .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
}
.price_des .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #022955;
    cursor: not-allowed;
}
.price_des .el-checkbox__label {
    display: flex;
    color: #022955;
}
.price_des .coll_child_num {
    float: right;
    width: 45px;

    font-size: 14px;
    color: #8497ab;
    margin-left: 5px;
    position: relative;
    top: 0px;
    left: -65px;
    text-align: end;
}
.price_des .coll_child_name {
    color: #022955;
    /* width: 210px;
     */
    width: 270px;
    list-style: circle;
    line-height: 22px;
}
.coll_child_name::marker {
    color: #55b419;
    font-size: 20px;
}
.coll_child_name1::marker {
    color: rgba(255, 192, 0);
    font-size: 20px;
}
.coll_child_name2::marker {
    color: rgba(237, 125, 49);
    font-size: 20px;
}
.coll_child_name3::marker {
    color: #1290c9;
    font-size: 20px;
}
/* .price_des .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #55b419;
    border: #55b419;
} */
.des_0 .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #55b419;
    border: #55b419;
}
.des_1 .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: rgba(255, 192, 0);
    border: rgba(255, 192, 0);
}
.des_2 .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #ff7600;
    border: #ff7600;
}
.des_3 .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #1290c9;
    border: #1290c9;
}
.price2 {
    margin-top: 20px !important;
    margin-bottom: 28px !important;
}
.yinying {
    background-color: #ccc !important;
}
.untis {
    line-height: 40px;
    font-size: 30px;
    font-family: Arial Bold;
    color: #022955;
}
.puy_dialog2 .el-dialog__body {
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.puy_dialog2 {
    height: 510px !important;
}
.pricenum {
    margin-top: 20px;
}

.puy_dialog2.el-dialog {
    width: 430px;
}

.card_img {
    position: relative;
    width: 180px;
    height: 250px;

    border-radius: 16px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: transform 0.3s ease;
}
.card_img:hover {
    transform: scale(1.1);
}
.card_img1 {
    /* background-image: url('../../assets/img/addcreate/company.png'); */
    background-color: rgba(255, 0, 0, 0.5);
    /* box-shadow: 0px 0px 6px 2px  rgba(255, 0, 0,0.5 ); */
}
.card_img1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 16px; */
    background-size: cover;
    /* opacity: 0.5; */
    background-image: url('../../assets/img/addcreate/company.png');
}

.card_img2 {
    background-color: cadetblue;
    /* background-image: url('../../assets/img/addcreate/product.png'); */
}
.card_img2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 16px; */
    background-size: cover;
    /* opacity: 0.5; */
    background-image: url('../../assets/img/addcreate/product.png');
}
.card_img3 {
    background-color: rgb(0, 112, 192);
}
.card_img3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 16px; */
    background-size: cover;
    /* opacity: 0.5; */
    background-image: url('../../assets/img/addcreate/trade.png');
}
.card_img4 {
    background-color: rgb(237, 125, 49);
}
.card_img4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 16px; */
    background-size: cover;
    /* opacity: 0.44; */
    background-image: url('../../assets/img/addcreate/people.png');
}
.card_img5 {
    background: burlywood;
}
.card_img5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 16px; */
    background-size: cover;
    /* opacity: 0.44; */
    background-image: url('../../assets/img/addcreate/country.png');
}
.card_img6 {
    background: deepskyblue;
}
.card_img6::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-radius: 16px; */
    background-size: cover;
    /* opacity: 0.44; */
    background-image: url('../../assets/img/addcreate/news.png');
}
.font-title {
    margin-top: 160px;
    width: 160px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: flex;
    /* justify-content: center; */
    color: white;
    position: relative;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 0 10px 10px 0;

    padding: 8px 10px;
    padding-left: 0px;
}
.icon-text-box {
    height: 80px !important;
    width: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    text-align: center;
    margin-bottom: 10px;
}
.icon-text-box1 {
    width: 180px !important;
    display: flex;
    justify-content: center;

    text-align: center;
    margin-bottom: 10px;
}
.icon-text {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: 20px;
}
.icon-text:nth-last-of-type(1) {
    margin-right: -20px;
}
.icone {
    width: 60px;
    height: 60px;
    line-height: 50px;
    background: #ffe6f8;
    border-radius: 50%;
    text-align: center;

    margin-bottom: 15px;
}
.card_img1_sanjiao,
.card_img1_sanjiao1,
.card_img1_sanjiao2,
.card_img1_sanjiao3,
.card_img1_sanjiao4,
.card_img1_sanjiao5 {
    position: relative;
    top: 35px;
    height: 3px;
    width: 180px;
    border-radius: 16px;
    /* opacity: 0.5; */
    background: #1290c9;
}
.card_img1_sanjiao {
    background-color: rgb(85, 180, 25);
    /* background-color: rgba(255, 0, 0, 0.5); */
}
.card_img1_sanjiao1 {
    background: rgb(255, 192, 0);
}
.card_img1_sanjiao2 {
    background-color: rgb(237, 125, 49);
}

.card_img1_sanjiao3 {
    background-color: rgb(18, 144, 201);
}

.card_img1_sanjiao4 {
    background-color: cyan;
}
.card_img1_sanjiao5 {
    background: cornflowerblue;
}

.card_img1_sanjiao::after,
.card_img1_sanjiao1::after,
.card_img1_sanjiao2::after,
.card_img1_sanjiao3::after,
.card_img1_sanjiao4::after,
.card_img1_sanjiao5::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 10px solid transparent;
    border-top-color: #1290c9;
}
.card_img1_sanjiao::after {
    border-top-color: rgb(85, 180, 25);
}
.card_img1_sanjiao1::after {
    border-top-color: rgb(255, 192, 0);
}
.card_img1_sanjiao2::after {
    border-top-color: rgb(237, 125, 49);
}
.card_img1_sanjiao3::after {
    border-top-color: rgb(18, 144, 201);
}
.card_img1_sanjiao4::after {
    border-top-color: cyan;
}
.card_img1_sanjiao5::after {
    border-top-color: cornflowerblue;
}
.com {
    width: 85px;
    background-color: #ffffff;
    color: #1290c9;
    line-height: 44px;
    font-size: 14px;
}
.sec {
    background-color: #1290c9;
    width: 75px;
    line-height: 44px;
    text-align: center;
    font-size: 14px;
    border-radius: 0 10px 10px 0;
}
.inputprive {
    position: relative;
    top: 18px;
    height: 40px;
}
.hide-checkbox .el-checkbox__input {
    display: none !important;
}
.companyservices {
    height: 80px;
    width: 100%;
    text-align: center;
    font-family: 'Arial Bold';
    color: rgb(2, 41, 85);
    font-size: 28px;
}
</style>


